import AlgoliaSearch from "./algolia_search";
import makePaginationWidget from "./pagination_widget";
import ResultsResizer from "./results_resizer";
import resultsWidget from "./results_widget";

import { configure } from 'instantsearch.js/es/widgets';
import { connectHits, connectPagination } from 'instantsearch.js/es/connectors';
import { pushToDataLayer } from "../ga4/ga4_event";

const RESULTS_PER_PAGE = 10

const handleResults  = (renderOptions) => {
  resultsWidget(renderOptions);
  ResultsResizer.getInstance().listenFoResize(resultsWidget, renderOptions);
  if(renderOptions.results) {
    const query = renderOptions.results.query;

    $(".js-search-results-keywords").text(
      query.length === 0 ? 'results' : `results for "${query}"`
    );

    pushToDataLayer({
      event: 'search',
      search_term: query,
      search_type: 'organic',
      num_search_results: renderOptions.results.nbHits
    })
  }
}

const run = () => {
  const params = $.deparam(document.location.search.slice(1))
  const initialState = {
    query: params.keywords,
    page: params.page,
  };

  const algoliaSearch = new AlgoliaSearch("search-results-searchbox", initialState);
  const results = connectHits(handleResults);
  const paginationWidget = makePaginationWidget(algoliaSearch);
  const pagination = connectPagination(paginationWidget);

  algoliaSearch.addWidgets([
    configure({
      hitsPerPage: RESULTS_PER_PAGE,
    }),
    pagination({ container: ".search-results__pagination" }),
    results({ container: ".search-results__results" })
  ]);

  algoliaSearch.start();
  $('.ais-SearchBox-input').attr('aria-label', 'Search Input');
}

export default Object.freeze({ run })
