import Gpt from "./ads/Gpt";

function createGallery($gallery) {
  const $content = $gallery.find(".js-gallery-content");
  const $lightbox = $gallery.find(".js-gallery-lightbox");
  const $nextArrow = $gallery.find(".js-gallery-arrow-next");
  const $prevArrow = $gallery.find(".js-gallery-arrow-prev");
  const $resetOverlay = $gallery.find(".js-gallery-reset");
  const $slideCounter = $gallery.find(".js-slide-index");
  const $slider = $gallery.find(".js-gallery-slider");
  const $slides = $gallery.find(".js-gallery-slides");
  const $viewButton = $gallery.find(".js-gallery-view-button");

  const arrowDisabledClass = "gallery__lightbox__slider__arrow--disabled";
  const maxSlide = $slider.data("slide-count") - 1;
  const minSlide = 0;
  const slideWidth = $slider.data("slide-width");

  const currentSlideNumber = () => $slider.data("current-slide-number");

  $content.click(killEvent);
  $lightbox.click(closeGallery);
  $nextArrow.click(nextSlide);
  $prevArrow.click(previousSlide);
  $resetOverlay.click(resetGallery);
  $viewButton.click(openGallery);

  function showCaption(index) {
    $("li[data-caption]").hide();
    $(`li[data-caption="slide-${index}"]`).show();
    $slideCounter.text(index + 1);
  }

  function showSlide(index) {
    $slider.data("current-slide-number", index);
    $slides.css("transform", "translateX(" + index * -1 * slideWidth + "px)");

    showCaption(index);
    toggleArrows(index);
    if (index % 2 == 1) Gpt.refreshGalleryAds();
  }

  function closeGallery(e) {
    toggleMainScroll(true);
    $(document).off("keyup");
    $lightbox.removeClass("show");

    return killEvent(e);
  }

  function killEvent(e) {
    e.preventDefault();
    e.stopPropagation();

    return false;
  }

  function nextSlide(e) {
    const atMaxSlideAlready = currentSlideNumber() == maxSlide;
    const nextSlideNumber = Math.min(currentSlideNumber() + 1, maxSlide);

    showSlide(nextSlideNumber);
    if (atMaxSlideAlready) showResetOverlay();

    return killEvent(e);
  }

  function openGallery(e) {
    toggleMainScroll(false);
    $(document).on("keyup", function(e) {
      if (e.key === "Escape") closeGallery(e);
    });
    resetGallery(e);
    $lightbox.addClass("show");

    return killEvent(e);
  }

  function previousSlide(e) {
    const previousSlideNumber = Math.max(currentSlideNumber() - 1, minSlide);

    showSlide(previousSlideNumber);

    return killEvent(e);
  }

  function resetGallery(e) {
    showSlide(0);
    $resetOverlay.removeClass("show");

    return killEvent(e);
  }

  function showResetOverlay() {
    $nextArrow.addClass(arrowDisabledClass);
    $resetOverlay.addClass("show");
  }

  function toggleArrows(index) {
    $nextArrow.removeClass(arrowDisabledClass);
    $prevArrow.removeClass(arrowDisabledClass);
    $resetOverlay.removeClass("show");

    if (index === 0) $prevArrow.addClass(arrowDisabledClass);
  }

  function toggleMainScroll(scrollable) {
    let overflow = scrollable ? "visible" : "hidden";
    let display = scrollable ? "flex" : "none";

    $("body").css("overflow", overflow);
    $("html").css("overflow", overflow);
    $(".navbar").css("display", display);
  }
}

const run = () => {
  $(".gallery").each(function(index) {
    createGallery($(this));
  });
};

export default Object.freeze({ run });
