import AlgoliaSearch from "./algolia_search"

import { connectHits } from 'instantsearch.js/es/connectors'
import { debounce } from "../utils/debounce"
import { pushToDataLayer } from "../ga4/ga4_event"

const delayedPushToDataLayer = debounce(pushToDataLayer);
const addClickSearchEvent = (searchTerm) => {
  const searchResults = document.querySelectorAll('li.search-results__results-item a');

  for (let link of searchResults) {
    link.addEventListener('click', () => {
      pushToDataLayer({
        'event': 'click_search_result',
        'search_term': searchTerm,
        'search_result': link
      });
    });
  }
}

// render function for in page search results
const renderHits = (renderOptions, isFirstRender) => {
  const { widgetParams, results } = renderOptions;
  // grab the query
  const input = $.trim($('#search-input-container .ais-SearchBox-input').val());

  // dont repaint the DOM if query is empty or first render
  if(isFirstRender || input == '') {
    if(!isFirstRender) addClickSearchEvent(undefined);

    $('.ais-SearchBox-input').attr('aria-label', 'Search Input');
    $('.ais-SearchBox-submit').attr('aria-label', 'Search Button');

    $('.search-box__featured').show();
    const suggestionsShowing = $('.search-box__results > .d2').length > 0;
    if (suggestionsShowing) {
      $(widgetParams.container).data('html', $(widgetParams.container).html());
    } else {
      $(widgetParams.container).html($(widgetParams.container).data('html'));
    }
  } else {
    let resultsHTML = "";
    if (!results.hits.length) {
      resultsHTML += "<div class='search-box__no-results'>";
      resultsHTML += `We're sorry, but there are no results for "${input}".`;
      resultsHTML += "</div>";
    } else {
      resultsHTML += "<ul>";
      resultsHTML += results.hits.map((item) => {
        return `
          <li class="search-results__results-item">
            <a href='${item.resource_uri}'>${item._highlightResult.name.value}</a>
          </li>
        `
      }).join('');
      resultsHTML += "</ul>";

      pushToDataLayer({
        event: "search",
        search_term: input,
        search_type: "suggested",
        num_search_results: results.hits.length
      })
    }

    delayedPushToDataLayer({
      event: "search",
      search_term: input,
      search_type: "suggested",
      num_search_results: results.hits.length
    });

    $(widgetParams.container).html(resultsHTML);
    $('.search-box__featured').hide();
    addClickSearchEvent(input);
  }
}

const listen = () => {
  const algoliaSearch = new AlgoliaSearch("search-input-container");
  const customHits = connectHits(renderHits);

  algoliaSearch.addWidgets([ customHits({ container: ".search-box__results" }) ]);
  algoliaSearch.start();
}

export default Object.freeze({ listen });
