import $ from "jquery";
import MobileDetect from "mobile-detect";

const md = new MobileDetect(window.navigator.userAgent);
window.md = md;

const adSizes = [
  {
    placement: "leaderboard_1",
    sizes: [[728, 90], [970, 250], [970, 90]]
  },
  {
    placement: "leaderboard_2",
    sizes: [[728, 90], [300, 250]]
  },
  {
    placement: "leaderboard_3",
    sizes: [[728, 90], [300, 250]]
  },
  {
    placement: "leaderboard_4",
    sizes: [[728, 90], [300, 250]]
  },
  {
    placement: "leaderboard_5",
    sizes: [[728, 90], [300, 250]]
  },
  {
    placement: "leaderboard_6",
    sizes: [[728, 90], [300, 250]]
  },
  {
    placement: "leaderboard_7",
    sizes: [[728, 90]]
  },
  {
    placement: "leaderboard_8",
    sizes: [[300, 250]]
  },
  {
    placement: "leaderboard_9",
    sizes: [[728, 90]]
  },
  {
    placement: "rectangle_1",
    sizes: [[300, 250], [468, 60]]
  },
  {
    placement: "rectangle_2",
    sizes: [[300, 250], [468, 60]]
  },
  {
    placement: "rectangle_3",
    sizes: [[300, 250], [468, 60]]
  },
  {
    placement: "rectangle_4",
    sizes: [[300, 250], [468, 60]]
  },
  {
    placement: "rectangle_5",
    sizes: [[300, 250], [468, 60]]
  },
  {
    placement: "rectangle_6",
    sizes: [[300, 250], [468, 60]]
  },
  {
    placement: "rectangle_7",
    sizes: [[300, 250]]
  },
  {
    placement: "rectangle_8",
    sizes: [[300, 250]]
  },
  {
    placement: "rectangle_9",
    sizes: [[300, 250]]
  }
];

const dynamicTargets = {
  paramount: function() {
    return ["paramount", md.mobile() ? "paramount-5_6" : "paramount-16_9"];
  }
};

const setId = element => {
  const id = $(element).data("id");
  $(element).removeData("id");
  $(element).removeAttr("data-id");
  $(element).attr("id", id);
};

const getId = element => {
  return $(element).attr("id");
};

const lazyAdContainers = () => {
  const device = md.mobile() ? "mobile" : "desktop";
  const targets = $(`.ad-container[data-id][data-device="${device}"]`);

  return targets.toArray();
};

const adContainers = () => {
  const device = md.mobile() ? "mobile" : "desktop";
  const targets = $(`.ad-container[data-device="${device}"]`);

  return targets.toArray();
};

const extractAd = adPlacement => {
  const id = $(adPlacement).attr("id") || $(adPlacement).data("id");
  const device = $(adPlacement).data("device");
  const placement = $(adPlacement).data("placement");
  const sizes = findAdSizes(placement);
  const slotName = `/7103/reviewed/${placement}`;

  return { id, placement, device, sizes, slotName };
};

const findAdSizes = placement => {
  return adSizes.find(adSize => {
    return adSize.placement == placement;
  }).sizes;
};

const pageTargets = () => {
  const targets = $('[data-gad-target="true"]');

  return targets
    .map((index, target) => {
      let result = {
        name: $(target).attr("name"),
        content: $(target).attr("content")
      };

      if ($(target).data("gad-dynamic")) {
        result.content = dynamicTargets[result.content]();
      }

      return result;
    })
    .toArray();
};

const ads = () => {
  const $adPlacements = adContainers();

  return $adPlacements.map(adPlacement => extractAd(adPlacement));
};

const setAdMetaTagValue = (name, value) => {
  const metatag = $(`meta[data-gad-target="true"][name="${name}"]`);

  return metatag && metatag.attr("content", value);
};

export default Object.freeze({
  ads,
  lazyAdContainers,
  pageTargets,
  setId,
  getId,
  setAdMetaTagValue
});
