class MainNavbar {
  constructor() {
    window.addEventListener("scroll", this.watchScroll);
  }

  watchScroll() {
    var navbar = document.querySelector(".navbar");
    var topNav = document.querySelector(".top-nav");
    var scrollTop = window.scrollY;

    if (scrollTop >= 52) {
      navbar.classList.add("navbar--compressed");
      topNav.classList.add("top-nav--hidden");
    } else if (scrollTop < 20) {
      navbar.classList.remove("navbar--compressed");
      topNav.classList.remove("top-nav--hidden");
    }

    // article-progress-bar
    var articleHeight = document.querySelector(".tbrn-article").clientHeight;
    var percentSeen = (scrollTop / articleHeight) * 100;
    var progressBar = document.querySelector(".article-progress-fill");
    progressBar.style.width = percentSeen + "%";
  }
}

export default MainNavbar;
