const checkArrows = (target) => {
  if (target.scrollLeft === 0) {
    $(target).parent(".slider").children(".slider__arrow-prev").addClass("slider__arrow--disabled");
  } else {
    $(target).parent(".slider").children(".slider__arrow-prev").removeClass("slider__arrow--disabled");
  }

  if (target.scrollLeft === target.scrollWidth - target.offsetWidth) {
    $(target).parent(".slider").children(".slider__arrow-next").addClass("slider__arrow--disabled");
  } else {
    $(target).parent(".slider").children(".slider__arrow-next").removeClass("slider__arrow--disabled");
  }
}

const scroll = (button, direction) => {
  let el = $(button).parents(".slider").children(".slider__content");
  let scrollX = el.scrollLeft() + (window.innerWidth - (window.innerWidth * 0.2)) * direction;

  el.animate({ scrollLeft: scrollX }, 200);
}

const run = () => {
  $(".slider__content").on("scroll", (event) => {
    checkArrows(event.target);
  });

  $(".slider__arrow-prev").on("click", (event) => { scroll(event.target, -1) });
  $(".slider__arrow-next").on("click", (event) => { scroll(event.target, 1) });

  $(".slider__content").each((_, el) => checkArrows(el));
}

export default Object.freeze({ run });
