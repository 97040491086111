const run = () => {
  const { gdpr, parsely_site_id, tracking_allowed } =
    (window && window.js_vars) || {};
  const one_trust_disabled = !tracking_allowed;

  if (one_trust_disabled || gdpr) {
    window.PARSELY = window.PARSELY || {
      onload: function() {
        PARSELY.setConfigOptions({
          track_ip_addresses: false,
          track_third_party_cookies: false
        });
      }
    };
  }

  var parsely = document.createElement("script");
  parsely.src = `//cdn.parsely.com/keys/${parsely_site_id}/p.js`;
  var script = document.getElementsByTagName("script")[0];
  script.parentNode.insertBefore(parsely, script);
};

export default Object.freeze({ run });
