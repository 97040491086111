// OneTrust must be run first unless GDPR
// ExternalLinks must be run first to disable affiliate tracking on Amazon links

const run = () => {
  if (window.js_vars.targeting_allowed || window.js_vars.gdpr) {
    let $el = $("#amazon-one-tag-script");
    $el.attr("src", $el.attr("data-src"));
  }
};

export default Object.freeze({ run });
