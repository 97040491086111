const makePaginationWidget = (algoliaSearch) => {
  return (renderOptions, isFirstRender) => {
    const { widgetParams, currentRefinement, nbHits, nbPages } = renderOptions

    $('#search-results__hitcount').text(nbHits > 0 ? nbHits : "---")

    let paginationHTML = ""
    let page = parseInt(currentRefinement + 1);
    const keywords = algoliaSearch.query;
    const paginationUrl = window.location.href.replace(/keywords=[^\&]+/, `keywords=${keywords.replace(' ', '%20')}`);

    if(page > 1) {
      let prev_page = paginationUrl.replace(/page=\d+/, `page=${page - 1}`)
      paginationHTML += `<a class="search-results__pagination-btn" href="${prev_page}">`
      paginationHTML += '<p>Previous</p>'
      paginationHTML += '</a>'
    } else {
      paginationHTML += '<p></p>'
    }
    paginationHTML += `<p class="search-results__pagination-counter">Page ${page} of ${nbPages}</p>`
    if(page < nbPages) {
      let next_page = paginationUrl.replace(/page=\d+/, `page=${page + 1}`)
      paginationHTML += `<a class="search-results__pagination-btn" href="${next_page}">`
      paginationHTML += '<p>Next</p>'
      paginationHTML += '</a>'
    }

    $(widgetParams.container).html(paginationHTML)
  }
}

export default makePaginationWidget;
