import $ from "jquery";

const addBlankTarget = () => {
  const currentHostname = window.location.hostname.toLowerCase();
  const whitelistedDomains = ['reviewed.com', 'reviewed.usatoday.com', 'cm.usatoday.com'];

  $("a[href*='http'], a[href*='affiliate']").each(function() {
    const linkDestination = this.href.toLowerCase()
    const linkHostname = new URL(linkDestination).hostname;

    const isExternal = linkHostname !== currentHostname && !whitelistedDomains.includes(linkHostname);
    const isAffiliate = linkDestination.includes('affiliate');

    if(isExternal || isAffiliate) {
      $(this).attr("target", "_blank");
    }
  });
};

const run = () => {
  addBlankTarget();
};

export default Object.freeze({ run });
