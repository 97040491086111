import $ from "jquery"
// Currently inserting a value into the honeypot that will be present for 5 seconds
// Robots will submit values within said 5 seconds

const removeHoneypotValue = () => {
  $('[value="an email signup form"]').each(function(i, element) {
    $(element).attr("value", "")
  });
}

const run = (time = 5000) => {
  setTimeout(() => {
    removeHoneypotValue()
  }, time)
}

export default Object.freeze({ run, removeHoneypotValue });
