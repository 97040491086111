import { pushToDataLayer } from "../ga4/ga4_event";

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const addMultiLineEllipsis = (items) => {
  const textContainerHeight= $('.multilineEllipseText__container').height();
  const multilineEllipseText = $('.multilineEllipseText');

  multilineEllipseText.each(function (index) {
    var $ellipsisText = $(this);

    if (window.innerWidth > 768 && $ellipsisText.outerHeight(true) > textContainerHeight) {
      while ($ellipsisText.outerHeight(true) > textContainerHeight) {
        $ellipsisText.text(function(index, text) {
          return text.replace(/\W*\s(\S)*$/, '...');
        });
      }
    }
  });
}

const addClickSearchEvent = (searchTerm) => {
  const searchResults = document.querySelectorAll('li.search-results__results-item');
  for (let result of searchResults) {
    const resultLinks = result.getElementsByTagName('a');
    for (let link of resultLinks) {
      link.addEventListener('click', () => {
        pushToDataLayer({
          'event': 'click_search_result',
          'search_term': searchTerm,
          'search_result': link
        });
      });
    }
  }
}

const resultsWidget = (renderOptions) => {
  const { widgetParams, results } = renderOptions

  // dont repaint the DOM if query is empty or first render
  if(results && results.hits && results.hits.length > 0) {
    let resultsHTML = results.hits.map((item) => {
      let classification = ''
      if (item.classification) {
        classification = '<label class="classification-label">'
        classification += capitalize(item.classification.replace(/-/g, ' '))
        classification += '</label>'
      }

      return `<li class="search-results__results-item">
        <div class="reviewed-card reviewed-card--">
          <div class="row">
            <div class="col-md-4 col-5 featured-article__image-container">
              <a href='${item.resource_uri}'>
                <img src="${item.hero_url}" alt="" class="featured-article__image">
              </a>
            </div>
            <div class="col-7">
              <span class="featured-article__desc">
                ${classification}
                <a href='${item.resource_uri}'>
                  <h4 class="featured-article__title u1">
                    ${item._highlightResult.name.value}
                  </h4>
                </a>
                <div class="multilineEllipseText__container">
                  <p class="featured-article__description multilineEllipseText">
                    ${item.summary}
                  </p>
                </div>
                <a href='${item.resource_uri}' class="card-link">Read more</a>
              </span>
            </div>
          </div>
        </div>
      </li>`
    }).join('')
    $(widgetParams.container).html(resultsHTML);

    addMultiLineEllipsis();
    addClickSearchEvent(results.query);
  } else {
    if (results) { // results will be undefined while still searching
      $('#search-results__hitcount').text(0);
      $(widgetParams.container).html("<p style='text-align: center'>No Results</p>")
    }
  }
}

export default resultsWidget;
