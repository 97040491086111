/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "../javascripts/shared.js";

import { animateNumbers, animateCards } from "../javascripts/animations";
import { setCardHeight } from "../javascripts/cardHeights";
import InfiniteScroll from "../javascripts/infinite_scroll";
import Newsletter from "../javascripts/components/newsletter/newsletter.js"
import trackScrollDepth from "../javascripts/ga4/scroll_depth.js"
import Slider from "../javascripts/slider";

$(() => {
  Slider.run();
  animateNumbers();
  animateCards();
  InfiniteScroll.run();
  trackScrollDepth();
  setCardHeight();
});
