$(() => {
  $(".article-side-nav__link").on("click", function (e) {
    e.preventDefault();
    var id = $(e.target).attr("href");
    var $target = $(id);

    if ($(".article-side-nav").hasClass("article-side-nav--expanded")) {
      $(".article-side-nav").removeClass("article-side-nav--expanded");

      setTimeout(() => {
        $("html, body").animate(
          { scrollTop: $target.offset().top - 105 },
          100,
          "linear"
        );
      }, 500);
    } else {
      $("html, body").animate(
        { scrollTop: $target.offset().top - 65 },
        100,
        "linear"
      );
    }
  });

  // for nav on articles
  let rootMargin;
  var observer = new IntersectionObserver(
    (entries) => {
      rootMargin =
        window.innerWidth > 768 ? "0px 0px -80% 0px" : "0px 0px -105px 0px";
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          $(".article-side-nav__link--active").removeClass(
            "article-side-nav__link--active"
          );
          $(
            "a[href='#" +
              entry.target.getAttribute("id") +
              "'].article-side-nav__link"
          ).addClass("article-side-nav__link--active");
        }
      });
    },
    { rootMargin }
  );
  var sections = document.querySelectorAll(".u3, .c-product-widget__name, h2");
  sections.forEach((section) => {
    observer.observe(section);
  });

  const tbrnArticlePage = $(".tbrn-article");

  if (tbrnArticlePage && tbrnArticlePage.length > 0) {
    $("body").css("display", "contents");
    if (window.innerWidth < 768) {
      runMobileSidebarNavigation();
    }
  }

  var resizeId;
  $(window).one("resize", function () {
    clearEventListenrs();
    clearTimeout(resizeId);
    resizeId = setTimeout(function () {
      if (window.innerWidth < 768) {
        runMobileSidebarNavigation();
      }
    }, 700);
  });
});

// Mobile navigation
function runMobileSidebarNavigation() {
  let sections = [];

  const $jumpLinks = $(".article-side-nav").find("a");
  $jumpLinks.each((index, $link) => {
    sections.push($("#" + $link.href.replace(location.href + "#", ""))[0]);
  });

  var observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        $(entry).removeClass("active");

        if (entry.intersectionRatio > 0) {
          $(entry.target).addClass("active");

          $jumpLinks.each((index, $link) => {
            if ($link.href === location.href + "#" + entry.target.id) {
              $($(".article-mobile-nav").find("li")[index]).addClass("active");
              $(".article-mobile-nav")
                .find("ul")
                .css(
                  "transform",
                  "translateX(-" + window.innerWidth * index + "px)"
                );
            } else {
              $($(".article-mobile-nav").find("li")[index]).removeClass(
                "active"
              );
            }
          });

          checkForDisabledButton();
        }
      });
    },
    { rootMargin: "0px 0px -105px 0px" }
  );

  sections.forEach((section) => {
    observer.observe(section);
  });

  listenForMobileNavClick(sections);
  listenForExpansionClick();
  checkForDisabledButton();
}

function listenForMobileNavClick() {
  $(".article-mobile-nav__button--next").on("click", updateMobileNavigation);

  $(".article-mobile-nav__button--prev").on("click", updateMobileNavigation);
}

function updateMobileNavigation() {
  direction = $(this).hasClass("article-mobile-nav__button--next")
    ? "next"
    : "prev";
  const activeIndex = $(".article-mobile-nav").find("li").index($(".active"));
  const nextActiveEl = $(".article-mobile-nav")
    .find("li")
    .eq(direction === "next" ? activeIndex + 1 : activeIndex - 1);
  const jumpUrl = nextActiveEl.find("p").data("url");

  const anchorTag = $("#" + jumpUrl);
  $("html,body").animate(
    { scrollTop: anchorTag.offset().top - 105 },
    100,
    "linear"
  );

  checkForDisabledButton();
}

function checkForDisabledButton() {
  const $mobileNavListItems = $(".article-mobile-nav").find("li");
  const activeIndex = $mobileNavListItems.index($(".active"));

  $(".article-mobile-nav__button--prev").prop("disabled", activeIndex === 0);
  $(".article-mobile-nav__button--next").prop(
    "disabled",
    activeIndex === $mobileNavListItems.length - 1
  );
}

function listenForExpansionClick() {
  $(".article-mobile-nav__text").on("click", toggleNavExpansion);
}

function toggleNavExpansion() {
  $(".article-side-nav").toggleClass("article-side-nav--expanded");
}

function clearEventListenrs() {
  $(".article-mobile-nav__text").off("click", toggleNavExpansion);
  $(".article-mobile-nav__button--prev").off("click", updateMobileNavigation);
  $(".article-mobile-nav__button--next").off("click", updateMobileNavigation);
}

window.smoothScrollToId = function (id) {
  const target = $(id);
  $("html, body").animate(
    { scrollTop: target.offset().top - 65 },
    300,
    "linear"
  );
};
