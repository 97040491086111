import $ from "jquery";
import { pushToDataLayer } from "../../ga4/ga4_event";

$(".signup__card__sample").on("click", function(event) {
  event.stopPropagation()
  const title = $(event.target).attr("data-title")
  pushToDataLayer({'event': 'click_newsletter_sample', 'newsletter_title': title});
})

$(".signup__card").on("click", function(event) {
  const card = $(event.currentTarget)
  const checkbox = card.find(".signup__card__selector__checkbox");

  const isCardSelected = card.hasClass('selected');
  const classToRemove = isCardSelected ? 'selected' : 'unselected'
  const classToAdd = isCardSelected ? 'unselected' : 'selected'
  card.removeClass(classToRemove).addClass(classToAdd);
  checkbox.attr("checked", !isCardSelected);

  const submitDisabled = $('.signup__card__selector__checkbox:checked').length === 0
  $('#newsletter-submit').attr('disabled', submitDisabled);

  pushToDataLayer({
    event: 'click_newsletter_toggle',
    newsletter_title: card.find(".signup__card__selector").attr("data-card")
  })
})

$("#newsletter-submit").on("click", function(event) {
  const checkboxes = $(".signup__card__selector__checkbox:checked")
  const checkboxValues = $.map(checkboxes, function(checkbox) {
    return checkbox.value;
  })
  const emailInput = $("input[name=email]");
  const nameInput = $("input[name=name]");
  const zipcodeInput = $("input[name=zip_code]");
  const messageDiv = $("#message");
  const honeypot = $('input[data-param="moreinfo"]')[0]

  $.ajax({
    url: "/newsletters",
    type: "POST",
    data: {
      "email": emailInput.val(),
      "first_name": nameInput.val(),
      "source": "Newsletters",
      "cta_ids": checkboxValues,
      "website": "Newsletters",
      "zip_code": zipcodeInput.val(),
      "moreinfo": honeypot.value
    },
    dataType: "json"
  })
  .done(function(response) {
    let successMessage = response.message;

    messageDiv.html(`<span class="signup__submit__msg signup__submit__msg--success">${successMessage}</span>`)
    emailInput.val("");
    nameInput.val("");
    zipcodeInput.val("");

    $(".signup__card__selector__checkbox:checked").each((_idx, checkbox) => {
      pushToDataLayer({
        'event': 'newsletter_signup_complete',
        'newsletter_title': checkbox.name,
        'newsletter_location': '/newsletters'
      })
    })
  })
  .fail(function(response) {
    let errorMessage = response.responseJSON.message;

    messageDiv.html(`<span class="signup__submit__msg signup__submit__msg--error">${errorMessage}</span>`)

    $(".signup__card__selector__checkbox:checked").each((_idx, checkbox) => {
      pushToDataLayer({
        'event': 'newsletter_signup_failure',
        'newsletter_title': checkbox.name,
        'failure_reason': errorMessage,
        'newsletter_location': '/newsletters'
      })
    })
  })
})
