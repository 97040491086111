const videoGrabber = () => {
  let videos = []
  const scripts = document.querySelectorAll(".video-script")

  scripts.forEach(script => videos.push(JSON.parse(script.innerHTML)))

  return videos
}

const videoInitializer = (video) => {
  if (!video) return false;

  new window.Teal.Player(
    {
      video: `tealPlayer_${video.id}`,
      container: `tealContainer_${video.id}`,
      adContainer: `adContainer_${video.id}`,
    },
    {
      base: { debug: true, autoplay: location.pathname.includes('/video'), closedCaption: true },
      ias: { load: true, enable: true },
      ima: {
        accountName: 'reviewed',
        enable: true,
        load: true,
        mobileAccountName: 'reviewed',
        mobilePlacement: 'preroll',
        placement: 'preroll'
      },
      gcianalytics: { load: true, enable:true },
      comscore: { load: true, enable: true },
      hls: { load: true, enable: true },
      controls: {
        enable: true,
        enableBigPlayButton: true,
        svg: {
          exitFullscreen: {
            title: 'Exit Full Screen',
            path: '<path class="st0" d="M12,0C7.4,0,3.7,2.8,3.7,9.4C3.7,16.1,7.4,24,12,24s8.3-7.9,8.3-14.6C20.3,2.8,16.6,0,12,0z M7.2,16.2 c-3.1-3.5-2.8-7.1-2.2-9c0.4,3,5.3,6.2,5.3,6.2S9.9,14.5,7.2,16.2z M13.6,13.4c0,0,5-3.2,5.3-6.2c0.6,1.9,0.9,5.5-2.2,9 C14.1,14.5,13.6,13.4,13.6,13.4z"/>'
          }
        }
      },
      continuousPlay: {
        enable: false
      },
      share: {
        enableShareAPI: false
      }
    },
    {
      siteCode: 'USAT',
      displayName: 'Reviewed',
    },
    video
  );
}

const run = () => {
  const videos = videoGrabber();

  videos.forEach((video) => videoInitializer(video))
}

export default Object.freeze({ run, videoGrabber, videoInitializer });
