export default class ResultsResizer {
  static instance;
  static getInstance() {
    if(!ResultsResizer.instance) ResultsResizer.instance = new ResultsResizer();
    return ResultsResizer.instance;
  }

  constructor() {
    if(ResultsResizer.instance) return ResultsResizer.instance;
    ResultsResizer.instance = this;
  }

  listenFoResize(handler, renderOptions) {
    $(window).off('resize', this.handler);
    this.handler = () => {
      clearTimeout(this.resizeId);
      this.resizeId = setTimeout(handler.bind(null, renderOptions), 300);
    }
    $(window).on('resize', this.handler);
  }
}
