import Cookies from "js-cookie";
import deparam from "jquery-deparam";

$(() => {
  $("#reset_cache").on("click", () => {
    up.request(document.URL, {
      method: "PURGE",
      headers: {
        "Fastly-Key": window.js_vars.global.fastly_api_token,
        "X-Fastly-Country-Code": window.js_vars.target_country
      }
    })
      .then(function(response) {
        alert("The cache for this page has successfully been rebuilt");
      })
      .catch(function() {
        alert("Something went wrong rebuilding the cache");
      });
  });

  $("#reset_hooper").on("click", () => {
    const articleURL = location.href.replace(location.search, "");
    const $button = $("#reset_hooper");
    const originalText = $button.html();

    $button.html("Resetting...");

    $.ajax({
      crossDomain: true,
      type: "POST",
      url: `${articleURL}/reset`
    })
      .done(function(_data, _textStatus, _jqXHR) {
        alert("Hooper was successfully reset");
      })
      .fail(function(_jqXHR, _textStatus, _errorThrown) {
        alert("Something went wrong resetting hooper");
      })
      .always(function() {
        $button.html(originalText);
      });
  });
});
