const timers = {};

/**
 * Numbers functionality
 * @param {Element} el Document element
 * @param {number} start Start number
 * @param {number} end End number
 * @param {number} duration Duration in ms
 */
function incrementNumbers(el, start, end, duration) {
  const range = end - start;
  let current = start;
  const increment = end > start? 1 : -1;
  const stepTime = Math.abs(Math.floor(duration / range));
  if (timers[end]) {
    clearInterval(timers[end]);
  }
  timers[end] = setInterval(() => {
    current += increment;
    el.innerHTML = current.toLocaleString();
    if (current === end) {
      clearInterval(timers[end]);
    }
  }, stepTime);
}

// month calculation
function monthDiff(dateFrom, dateTo) {
  let months = dateTo.getMonth() - dateFrom.getMonth() +
    (12 * (dateTo.getFullYear() - dateFrom.getFullYear()));
  if (months < 0) months = 0;
 return months;
}

/**
 * Numbers animation
 */
export function animateNumbers() {
  const numbers = document.querySelectorAll('.spinning-number__number');
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        let end = parseFloat(entry.target.dataset.value.replace(/,/g, ''));
        const increment = parseFloat(entry.target.dataset.increment) || 0;
        const months = monthDiff(new Date('2019', '07'), new Date());
        end += increment * months;
        incrementNumbers(entry.target, end - 100, end, Math.random() * 2000);
      }
    });
  });
  numbers.forEach((number) => {
    observer.observe(number);
  });
}


/**
 * Cards animation
 */
export function animateCards() {
  const cards = document.querySelectorAll(".card, .featured-articles-group__card-hero");
  const observer = new IntersectionObserver(items => {
    items.forEach((item) => {
      item.intersectionRatio > 0 && item.target.classList.add("animate")
    })
  });
  cards.forEach((card) => {
    observer.observe(card);
  });
}
