import { pushToDataLayer } from "../../assets/javascripts/ga4/ga4_event";

const run = () => {
  $("[data-column-index]").hover(
    function () {
      const columnIndex = $(this).attr("data-column-index");
      $(`[data-column-index="${columnIndex}"]`).addClass("selected");
    },
    function () {
      $("[data-column-index]").removeClass("selected");
    }
  );
};

$("[data-click-url]").on("click", function (e) {
  if (e.target.nodeName !== "A") {
    pushToDataLayer({
      event: $(this).attr("data-ga4-event"),
      affiliate_merchant: $(this).attr("data-ga4-affiliate-merchant"),
      affiliate_network: $(this).attr("data-ga4-affiliate-network"),
      affiliate_button_type: "Product Grid Column",
      affiliate_button_location: "Product Grid Column",
      price_upon_click: $(this).attr("data-ga4-price-upon-click"),
      product_category: $(this).attr("data-ga4-product-category"),
      product_name: $(this).attr("data-ga4-product-name")
    });
  }

  let url = $(this).attr("data-click-url");
  if (e.target.tagName != "A" && url) {
    window.open(url, "_blank");
  }
});

export default Object.freeze({ run });
