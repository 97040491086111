const FAILSAFE_TIMEOUT = 3000;

const adSlots = ads => {
  return ads.map(ad => {
    return {
      adSlotId: ad.placement,
      size: ad.sizes,
      adUnitPath: ad.slotName
    };
  });
};

const run = ads => {
  ads.forEach((ad) => {
    ad.sizes = ad.sizes.filter(s => s !== "fluid");
  });
  return new Promise((resolve, reject) => {
    window.__iasPET.queue.push({
      adSlots: adSlots(ads),
      dataHandler: () => {
        window.__iasPET.setTargetingForGPT();
        resolve();
      }
    });

    window.setTimeout(resolve, FAILSAFE_TIMEOUT);
  });
};

export default Object.freeze({ run });
