import Cookies from "js-cookie";
import qs from "qs";

const GROUPS = {
  functional: "3",
  necessary: "1",
  performance: "2",
  targeting: "4"
};

const VALUES = {
  enabled: "1",
  disabled: "0"
};

const DEFAULT_VALUE = VALUES["enabled"];

function trackingAllowed(cookie) {
  return groupValue(GROUPS["performance"], cookie) !== VALUES["disabled"];
}

function targetingAllowed(cookie) {
  return groupValue(GROUPS["targeting"], cookie) !== VALUES["disabled"];
}

function groupValue(group, cookie) {
  const groupValues = parseGroupValues(cookie);

  return groupValues[group] || DEFAULT_VALUE;
}

function parseGroups(cookie) {
  const params = qs.parse(cookie);

  return params.groups || "";
}

function parseGroupValues(cookie) {
  const groups = parseGroups(cookie);
  const hash = {};

  for (let group of groups.split(",")) {
    const [key, value] = group.split(":");

    hash[key] = value;
  }

  return hash;
}

const run = () => {
  const cookie = Cookies.get("OptanonConsent");

  window.js_vars.tracking_allowed = trackingAllowed(cookie);
  window.js_vars.targeting_allowed = targetingAllowed(cookie);
};

export default Object.freeze({ run, trackingAllowed });
