class MainMenu {
  constructor() {
    this.menuElement = document.getElementById("main_menu");
    this.currentSubMenu = null;
    this.mainMenuButton = document.getElementById("main_menu_trigger");
  }

  open() {
    this.menuElement.classList.add("main_menu--open");
    this.mainMenuButton.classList.add("menu--open");
    this.showOverlay();
  }

  close() {
    this.closeSubmenu(() => {
      this.menuElement.classList.remove("main_menu--open");
    });
    this.hideOverlay();
    this.mainMenuButton.classList.remove("menu--open");
  }

  toggle() {
    if (this.menuElement.classList.contains("main_menu--open")) {
      this.close();
    } else {
      this.open();
    }
  }

  showOverlay() {
    this.overlay = document.createElement("div");
    this.overlay.classList.add("main_menu-overlay");
    this.overlay.addEventListener("click", () => this.close());
    document.body.appendChild(this.overlay);
    setTimeout(() => {
      this.overlay.classList.add("main_menu-overlay-visible");
    }, 0);
  }

  hideOverlay() {
    this.overlay.addEventListener("transitionend", () => {
      document.body.removeChild(this.overlay);
    });
    this.overlay.classList.remove("main_menu-overlay-visible");
  }

  openSubmenu(category) {
    const nextMenu = document.getElementById(`toggle-${category}`);
    if (this.currentSubMenu === nextMenu) {
      this.closeSubmenu();
    } else {
      this.closeSubmenu(() => {
        this.currentSubMenu = nextMenu;
        this.currentSubMenu.classList.add("submenu--open");
        this.currentSubMenu.classList.remove("submenu--close");
      });
    }
  }

  closeSubmenu(callback) {
    if (this.currentSubMenu) {
      const handleTransitionEnd = () => {
        this.currentSubMenu.removeEventListener(
          "transitionend",
          handleTransitionEnd
        );
        this.currentSubMenu = null;
        if (callback) callback();
      };

      this.currentSubMenu.addEventListener(
        "transitionend",
        handleTransitionEnd
      );
      this.currentSubMenu.classList.remove("submenu--open");
      this.currentSubMenu.classList.add("submenu--close");
    } else {
      if (callback) callback();
    }
  }

  handleToggleClick(event) {
    event.preventDefault();
    this.toggle();
  }

  handleSubmenuClick(event, category) {
    event.preventDefault();
    this.openSubmenu(category);
  }
}

export default MainMenu;
