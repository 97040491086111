import { pushToDataLayer } from "./ga4/ga4_event"

const run = () => {
  class EmailCTASignUp {
    NEWSLETTER_API_URL = '/newsletters'
    POST_METHOD = 'POST'

    constructor(viewElement) {
      this.viewElement = $(viewElement)
      this.view1 = this.viewElement.find(".js-email-container")
      this.view2 = this.viewElement.find(".js-name-zip-container")
      this.view3 = this.viewElement.find(".js-completed-container")
      this.view1SubmitButton = this.view1.find(".js-email-submit-button")
      this.view2SubmitButton = this.view2.find(".js-name-zip-button")
      this.view1SubmitButton.click(this.onView1Submit.bind(this))
      this.view2SubmitButton.click(this.onView2Submit.bind(this))

      this.form1 = $(this.view1).find('form')[0]
      this.form2 = $(this.view2).find('form')[0]
    }

    onView1Submit(e) {
      this.view1.hide()
      this.view2.show()
      const formData = new FormData(this.form1)

      fetch(this.NEWSLETTER_API_URL, { method: this.POST_METHOD, body: formData } )
      .then(response => response.json())
      .then(json => {
        if (json.message === 'Subscription confirmed!') {
          pushToDataLayer({
            'event': 'newsletter_signup_complete',
            'newsletter_title': 'main',
            'newsletter_location': this.formLocation()
          })
        } else {
          pushToDataLayer({
            'event': 'newsletter_signup_failure',
            'newsletter_title': 'main',
            'failure_reason': json.message,
            'newsletter_location': this.formLocation()
          })
        }
      })
    }

    onView2Submit() {
      this.view2.hide();
      this.view3.show()
      const formData1 = new FormData(this.form1)
      const formData2 = new FormData(this.form2)

      formData2.set("email", formData1.get("email"))

      fetch(this.NEWSLETTER_API_URL, { method: this.POST_METHOD, body: formData2 } )
    }

    formLocation() {
      if($('.persistent-email-cta').attr('class').includes('slide-in')) {
        return 'persistent'
      } else {
        return 'footer'
      }

    }
  }

  window.ctas = [];
  $(".js-cta-container").each((index, element) => {
    window.ctas.push(new EmailCTASignUp(element))
  })
}

export default Object.freeze({ run });
