// Code provided by Geniuslink: https://gist.github.com/jpasichnyk/ff4500bc37c61b7becb544280d0cd0f4

if (typeof Georiot == 'undefined') //The shield to avoid running the code twice
{
    var Georiot = Georiot || {};

    var Genius = Genius || {};

    window.Genius = Genius;

    Georiot.getLinkType = function (currentLinkHref) {
        var amazonRegex = /^(?!amazon\.jobs)(^amazon\.|^amzn\.com|^amzn\.co\.uk|^amzn\.to|^smile.amazon\.)/;
        var amazonLocalRegex = /local\.amazon\./;

        if (currentLinkHref.indexOf("target.georiot.com") > 0 || currentLinkHref.indexOf("geni.us") > 0) {
            return "unknown";
        }

        var cleanprep = currentLinkHref.toLowerCase().replace("https://", "").replace("http://", "").replace("www.", "");
        if (amazonRegex.test(cleanprep) && !amazonLocalRegex.test(cleanprep)) return "amazon";
        else return "unknown";
    };

    Georiot.baseDomain = "//buy.geni.us";

    Georiot.createOnClickFunction = function (linkOnPage, destLink) {
        linkOnPage.setAttribute("data-geniuslink", destLink);
        return function () {
            if (linkOnPage.target === "_blank") {
                window.open(destLink, '_blank');
            } else {
                window.location.assign(destLink);
            }
            return false;
        };
    };

    Georiot.baseConvertLinks = function (tsid, types,
        passDtb, domain, useOnClick, specialProcessingForLinkTypes) {
        if (Georiot.utility.isThrive()) {
            return;
        }
        //Optional params javascript
        if (typeof domain === 'undefined') {
            domain = Georiot.baseDomain;
        }
        if (passDtb === false || passDtb === true) {
        }
        else {
            passDtb = false;
        }

        if (useOnClick === false || useOnClick === true) {
        }
        else {
            useOnClick = false;
        }
        /**
         * Ends optional params
         */

        var numberOfLinks = document.links.length;
        for (var currentLinkIndex = 0; currentLinkIndex < numberOfLinks; currentLinkIndex++) {
            var currentLink = document.links[currentLinkIndex];
            if (currentLink.hasAttribute('georiot-ignore') && currentLink.getAttribute('georiot-ignore') === 'true') {

            } else {
                var linkType = Georiot.getLinkType(currentLink.href);

                if (types.indexOf(linkType) > -1) {
                    var linkToUse = currentLink.href;
                    if (typeof specialProcessingForLinkTypes !== 'undefined') {
                        linkToUse = specialProcessingForLinkTypes(currentLink, linkType);
                    }
                    if (linkToUse !== "") {
                        var proxylink = domain +
                            "/Proxy.ashx?TSID=" +
                            tsid +
                            "&GR_URL=" +
                            encodeURIComponent(linkToUse);
                        if (passDtb) {
                            proxylink = proxylink + "&dtb=1";
                        }
                        if (useOnClick) {
                            currentLink.onclick = Georiot.createOnClickFunction(currentLink, proxylink);
                        } else {
                            currentLink.href = proxylink;
                        }
                    }

                } else continue;
            }
        }
    }

    Georiot.utility = {
        scriptIncluded: function (src) {
            var scripts = document.getElementsByTagName("script");
            for (var i = 0; i < scripts.length; i++)
                if (scripts[i].getAttribute('src') === src) return true;
            return false;
        },

        isThrive: function () {
            return window.location.search.indexOf('tve=true') > -1;
        }
    };

    Georiot.amazon = {
        convertLinks: function (tsid, passDtb, domain) {
            Georiot.amazon.convertToGeoRiotLinks(tsid, passDtb, domain);
        },
        convertToGeoRiotLinks: function (tsid, passDtb, domain) {
            Georiot.baseConvertLinks(tsid, ["amazon"], passDtb, domain, false);
        },
        addOnClickRedirect: function (tsid, passDtb, domain) {
            Georiot.baseConvertLinks(tsid, ["amazon"], passDtb, domain, true);
        }
    };

    Georiot.snippet = {
        convertLinks: function (tsid, passDtb, domain) {
            Georiot.snippet.convertToGeoRiotLinks(tsid, passDtb, domain);
        },
        convertToGeoRiotLinks: function (tsid, passDtb, domain) {

            Georiot.baseConvertLinks(tsid,
                [
                    "amazon"
                ],
                passDtb,
                domain,
                false,
                function (currentLink, linkType) {
                    return currentLink;
                }
            );
        }, addOnClickRedirect: function (tsid, passDtb, domain) {
            Georiot.baseConvertLinks(tsid,
                [
                    "amazon"
                ],
                passDtb,
                domain,
                true,
                function (currentLink, linkType) {
                    return currentLink;
                }
            );
        }
    };


    Genius.amazon = Georiot.amazon;
    Genius.snippet = Georiot.snippet;

    /*******************Compatibility section *****************************/
    /*This functions are floating here
    for compatability purposes with the amazon
    link engine plugin Once a new version of the plugin is out kill them with fire*/

    function convertToGeoRiotLinks(tsid) {
        var numberOfLinks = document.links.length;
        var currentLinkIndex = 0;

        for (currentLinkIndex = 0; currentLinkIndex < numberOfLinks; currentLinkIndex++) {
            var currentLink = document.links[currentLinkIndex];
            var linkType = getLinkType(currentLink.href);

            if (linkType == "amazon") {
                currentLink.href = "http://target.georiot.com/Proxy.ashx?TSID=" + tsid + "&GR_URL=" + encodeURIComponent(currentLink.href) + "&oldendpoint=true";
            } else continue;
        }
    }



    /* Returns link type: unknown, amazon
     */
    function getLinkType(currentLinkHref) {
        var amazonRegex = /\.amazon\./;
        var amazonLocalRegex = /local\.amazon\./;

        if (currentLinkHref.indexOf("target.georiot.com") > 0 || currentLinkHref.indexOf("geni.us") > 0) {
            return "unknown";
        }

        if (amazonRegex.test(currentLinkHref) && !amazonLocalRegex.test(currentLinkHref)) return "amazon";
        else return "unknown";
    }
}
