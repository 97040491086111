import externalLinks from "./external_links";

const run = () => {
  if ($("#infinite-scroll").length == 0) {
    return false;
  }

  const domTarget = $("#infinite-scroll").data("dom-target");
  const source = $("#infinite-scroll").data("source");

  const pagePagination = () => {
    const page = $("#infinite-scroll").data("page") || 2;

    up.replace(`${domTarget}:after`, `${source}?page=${page}`, {
      origin: $("#infinite-scroll"),
      history: false
    });

    $("#infinite-scroll").data("page", page + 1);
  };

  const cursorPagination = () => {
    const cursor = $("#infinite-scroll").data("cursor");

    up.replace(`${domTarget}:after`, `${source}?cursor=${cursor}`, {
      origin: $("#infinite-scroll"),
      history: false
    });

    up.on("up:fragment:inserted", function(event, fragment) {
      const cursor = $(fragment).data("cursor");
      $("#infinite-scroll").data("cursor", cursor);
    });
  };

  const callback = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        if ($("#infinite-scroll").data("cursor")) {
          cursorPagination();
        } else {
          pagePagination();
        }
        externalLinks.run();
      }
    });
  };

  const options = {
    rootMargin: "200px",
    threshold: 1.0
  };

  const observer = new IntersectionObserver(callback, options);
  const infiniteScroll = document.querySelector("#infinite-scroll");
  observer.observe(infiniteScroll);
};

export default Object.freeze({ run });
