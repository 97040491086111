const PREBID_TIMEOUT = 1000;
const FAILSAFE_TIMEOUT = 3000;

const config = {
  rectangle: [
    { bidder: "appnexus", params: { placementId: 14183571 } },
    { bidder: "appnexus", params: { placementId: 14183572 } },
    { bidder: "criteo", params: { zoneId: 1346110 } },
    { bidder: "criteo", params: { zoneId: 1346109 } },
    {
      bidder: "rubicon",
      params: { accountId: 11324, siteId: 220166, zoneId: 1082476 }
    },
    { bidder: "indexExchange", params: { siteId: 311491, size: [300, 600] } },
    { bidder: "indexExchange", params: { siteId: 311490, size: [300, 250] } },
    {
      bidder: "triplelift",
      params: { inventoryCode: "reviewed_300x600_desktop" }
    },
    {
      bidder: "triplelift",
      params: { inventoryCode: "reviewed_300x250_desktop" }
    },
    {
      bidder: "openx",
      params: { delDomain: "gannett-d.openx.net", unit: "540401087" }
    },
    {
      bidder: "openx",
      params: { delDomain: "gannett-d.openx.net", unit: "540401086" }
    }
  ],
  leaderboard: [
    { bidder: "appnexus", params: { placementId: 14183569 } },
    { bidder: "appnexus", params: { placementId: 14183573 } },
    { bidder: "criteo", params: { zoneId: 1346108 } },
    { bidder: "criteo", params: { zoneId: 1346111 } },
    {
      bidder: "rubicon",
      params: { accountId: 11324, siteId: 220166, zoneId: 1082476 }
    },
    { bidder: "indexExchange", params: { siteId: 311489, size: [970, 250] } },
    { bidder: "indexExchange", params: { siteId: 311492, size: [720, 90] } },
    {
      bidder: "triplelift",
      params: { inventoryCode: "reviewed_970x250_desktop" }
    },
    {
      bidder: "triplelift",
      params: { inventoryCode: "reviewed_728x90_desktop" }
    },
    {
      bidder: "openx",
      params: { delDomain: "gannett-d.openx.net", unit: "540401076" }
    },
    {
      bidder: "openx",
      params: { delDomain: "gannett-d.openx.net", unit: "540401089" }
    }
  ]
};

const getBids = ad => {
  if (ad.placement.match(/rectangle_[0-9]/)) {
    return config.rectangle;
  } else {
    return config.leaderboard;
  }
};

const filterPlacements = ads => {
  return ads.filter(ad => {
    return (
      ad.placement.match(/rectangle_[0-9]/) ||
      ad.placement.match(/leaderboard_[0-9]/)
    );
  });
};

const adUnits = ads => {
  ads = filterPlacements(ads);

  return ads.map(ad => {
    return {
      code: ad.slotName,
      mediaTypes: {
        banner: {
          sizes: ad.sizes.filter(s => Array.isArray(s))
        }
      },
      bids: getBids(ad)
    };
  });
};

const run = ads => {
  return new Promise((resolve, reject) => {
    const bidsBackHandler = () => {
      window.pbjs.setTargetingForGPTAsync();
      return resolve();
    };

    const runPrebid = () => {
      window.pbjs.aliasBidder("ix", "indexExchange");
      window.pbjs.addAdUnits(adUnits(ads));
      if (window.js_vars.ccpa_ads) {
        window.pbjs.setConfig({
          consentManagement: {
            usp: {
              cmpApi: "iab",
              timeout: 0
            }
          }
        });
      }
      window.pbjs.requestBids({
        bidsBackHandler: bidsBackHandler,
        timeout: PREBID_TIMEOUT
      });
    };

    window.pbjs.que.push(runPrebid);

    window.setTimeout(resolve, FAILSAFE_TIMEOUT);
  });
};

export default Object.freeze({ run });
