export function setCardHeight() {
  const $behindTheScenes = $('.behind-the-scenes');

  if ($behindTheScenes && $behindTheScenes.length > 0) {
    const $cards = $('.behind-the-scenes').find('.behind-the-scenes__card');
    getTextNodes($cards);
  }

  const $tbrnArticle = $('.tbrn-article');

  if ($tbrnArticle && $tbrnArticle.length > 0) {
    const $cardsBest = $('.tbrn-article__best').find('.tbrn-card');
    const $cardsOther = $('.other-contenders').find('.tbrn-card');
    setTimeout(() => {
      if ($cardsBest.length > 1) {
        const cardHeightsBest = $cardsBest.map(function() {
          return $(this).outerHeight();
        });

        getMaxHeight(cardHeightsBest, $cardsBest);
      }

      if ($cardsOther.length > 1) {
        const cardHeightsOther = $cardsOther.map(function() {
          return $(this).outerHeight();
        });

        getMaxHeight(cardHeightsOther, $cardsOther);
      }
    }, 500);
  }
}

function getMaxHeight(cardHeights, $cards) {
  const maxHeight = Math.max.apply(null, cardHeights);

  if ($('.tbrn-article').length > 0) {
    $cards.each((card, i) => {
      $(card).css('min-height', cardHeights[i])
    })
  } else {
    $($cards).outerHeight(maxHeight);
  }

  listenForResize();
}

function getTextNodes($cards) {
  const $blurbs = $('.behind-the-scenes').find('.behind-the-scenes__card-blurb');
  const cardWidth = $('.behind-the-scenes').find('.behind-the-scenes__card').outerWidth();
  const heights = [];

  $($blurbs).each(function(index, para) {
      const headerHeight = $($('.behind-the-scenes__card-header')[index]).height();
      $('.behind-the-scenes__scroll-container').append($('<p class="temp" id="temp--' + index +'">' + para.innerText + '</p>').width(cardWidth));

      heights.push(headerHeight + $('#temp--' + index).height() + 30 + (32 * 3)); // To account for button height & margin/padding
  })

  $('.behind-the-scenes__scroll-container').children('.temp').remove();

  getMaxHeight(heights, $cards);
}

function listenForResize() {
  var resizeId;
  $(window).one('resize', function() {
    clearTimeout(resizeId);
    resizeId = setTimeout(getTextNodes, 700);
  });
}
