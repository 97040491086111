const buildApiUrl = instagramId => {
  const queryString = [
    `url=http://instagr.am/p/${instagramId}`,
    `access_token=${window.js_vars.fb_client_token}`
  ].join("&");

  return `https://graph.facebook.com/v8.0/instagram_oembed?${queryString}`;
};

const run = () => {
  let instagrams = $(".instagram").map((_index, element) => {
    const instagram_id = element.getAttribute("data-id");
    const url = buildApiUrl(instagram_id);
    return fetch(url)
      .then(response => response.json())
      .then(json => {
        element.innerHTML = json.html;
      });
  });

  if(instagrams.length > 0) { // IE crashes if we don't do this check
    Promise.all(instagrams).then(() => {
      var script = document.createElement("script");
      script.src = "//www.instagram.com/embed.js";
      document.body.appendChild(script);
    });
  }
};

export default Object.freeze({ run });
