import { pushToDataLayer } from "./ga4_event";
import { debounce } from "../utils/debounce"

const determineThreshold = (currentDepth) => {
  const docHeight = $(document).height();
  const windowHeight = $(window).height();

  const scrollPercent = (currentDepth / (docHeight - windowHeight)) * 100

  if (scrollPercent <= 10) {
    return 0;
  } else if(scrollPercent <= 25) {
    return 10;
  } else if(scrollPercent <= 50) {
    return 25;
  } else if(scrollPercent <= 75) {
    return 50;
  } else if(scrollPercent <= 90) {
    return 75;
  } else {
    return 90;
  }
}

const trackScrollDepth = () => {
  let previousDepth = 0;
  let currentDepth;

  const delayedPush = debounce(() => {
    currentDepth = $(window).scrollTop();

    pushToDataLayer({
      event: "scroll",
      scroll_depth: currentDepth,
      scroll_threshold: determineThreshold(currentDepth),
      scroll_direction: previousDepth < currentDepth ? "down" : "up"
    })
  }, 1000);

  $(window).on("scroll", () => {
    delayedPush();

    previousDepth = currentDepth
  })
}

export default trackScrollDepth;

