import Cookies from "js-cookie";
import { daysFromToday } from "./date_helper";

const closeButtonSelector = ".js-persistent-email-cta-close-button";
const containerSelector = ".js-persistent-email-cta";
const cookieName = "PersistentEmailCTA";

// Premature Optimization:
// Cache these non-changing values outside the scroll function
// But set them inside run since that's called on $(document).ready()
let minScroll = null;

const oneWeekFromToday = daysFromToday(7);
const theEndOfTime = daysFromToday(364635);

function dismissContainer() {
  $(window).off("scroll", handleWindowScroll);
  toggleContainer(false);
}

function dontShowAgainUntil(date) {
  const timestamp = date.valueOf();

  Cookies.set(cookieName, timestamp);
}

function handleClickClose() {
  dismissContainer();
  dontShowAgainUntil(oneWeekFromToday);
}

function handleSignupCompleted() {
  setTimeout(dismissContainer, 1500);
  dontShowAgainUntil(theEndOfTime);
}

function handleWindowScroll() {
  const scrollPosition = $(window).scrollTop();
  const maxScroll = $(document).height() - 1.5 * $(window).height();
  const shouldShow = scrollPosition >= minScroll && scrollPosition <= maxScroll;

  toggleContainer(shouldShow);
}

function isCookieMissingOrExpired(cookieValue) {
  const timestamp = parseInt(cookieValue);

  return Number.isNaN(timestamp) || new Date(timestamp) <= new Date()
}

function shouldShowContainer() {
  const cookieValue = Cookies.get(cookieName);

  return isCookieMissingOrExpired(cookieValue);
}

function toggleContainer(shouldShow) {
  $(containerSelector).toggleClass("slide-in", shouldShow);
}

const run = () => {
  if (!shouldShowContainer()) return;

  // Super fancy calculations (jk not really, it just feels nice)
  // Start showing the CTA when we've scrolled down half of the window height
  // Stop showing it as the elements at the very bottom start coming into view
  minScroll = $(window).height() / 2;

  $(closeButtonSelector).click(handleClickClose);
  $(containerSelector).css("display", "flex");
  $(window).on("scroll", handleWindowScroll);
  $(document).on("click", ".js-email-signup-completed", handleSignupCompleted);
};

export default Object.freeze({ isCookieMissingOrExpired, run });
