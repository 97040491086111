import DomUtils from "./DomUtils";
import Prebid from "./Prebid";
import Ias from "./Ias";

const _adSlots = [];

const defineSlots = ads => {
  let seen = [];
  ads
    .filter(ad => {
      if (seen.indexOf(ad.id) > -1) return false;
      seen.push(ad.id);
      return true;
    })
    .forEach(ad => {
      _adSlots.push(defineSlot(ad));
    });
};

const defineSlot = ad => {
  return window.googletag
    .defineSlot(ad.slotName, ad.sizes, ad.id)
    .addService(window.googletag.pubads());
};

const defineTargets = targets => {
  const setTargeting = target => {
    window.googletag.pubads().setTargeting(target.name, target.content);
  };

  targets.map(setTargeting);
};

const enableSettings = () => {
  window.googletag.pubads().enableSingleRequest();
  window.googletag.pubads().collapseEmptyDivs();
  window.googletag.pubads().disableInitialLoad();
};

const displayAd = id => {
  window.googletag.cmd.push(() => {
    window.googletag.display(id);
  });
};

const displayAds = () => {
  const ads = DomUtils.ads();
  const pageTargets = DomUtils.pageTargets();

  window.googletag.cmd.push(() => {
    // setup settings
    enableSettings();

    // Define Slots
    defineSlots(ads);

    // Define Targets set in meta tags
    defineTargets(pageTargets);

    // Enable Services
    window.googletag.enableServices();

    // Load tags
    Promise.all([Ias.run(ads), Prebid.run(ads)]).then(() => {
      window.googletag.pubads().refresh();
    });
  });
};

const refreshAdSlots = adSlotArray => {
  googletag.cmd.push(function() {
    googletag.pubads().refresh(adSlotArray);
  });
};

const refreshGalleryAds = () => {
  const galleryAdSlots = _adSlots.filter(
    slot => slot.getAdUnitPath().replace(/.+_/g, "") >= 7
  );

  refreshAdSlots(galleryAdSlots);
};

export default Object.freeze({
  displayAds,
  displayAd,
  enableSettings,
  refreshGalleryAds
});
