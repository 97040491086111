class SearchMenu {
  constructor() {
    this.menuElement = document.getElementById("search_menu");
    this.closeButtonIcon = document.getElementById("search_menu_close_icon");
    this.openButtonIcon = document.getElementById("search_menu_search_icon");
  }

  open() {
    this.menuElement.classList.add("open");
    this.closeButtonIcon.classList.add("show");
    this.closeButtonIcon.classList.remove("hide");
    this.openButtonIcon.classList.remove("show");
    this.openButtonIcon.classList.add("hide");
    document.body.classList.toggle("no-scroll");

    setTimeout(
      () => document.querySelector(".search-input-container-input").focus(),
      100
    );
    document.addEventListener("keydown", this.navigateSearchResults);
  }

  close() {
    this.menuElement.classList.remove("open");
    this.closeButtonIcon.classList.add("hide");
    this.closeButtonIcon.classList.remove("show");
    this.openButtonIcon.classList.add("show");
    this.openButtonIcon.classList.remove("hide");
    document.removeEventListener("keydown", this.navigateSearchResults);

    const selected = document.querySelector("ul li.selected");
    if (selected) {
      selected.classList.remove("selected");
    }
  }

  toggle() {
    if (this.menuElement.classList.contains("open")) {
      this.close();
    } else {
      this.open();
    }
  }

  handleToggleClick(event) {
    event.preventDefault();
    this.toggle();
  }

  navigateSearchResults(e) {
    const DOWN = 40;
    const UP = 38;
    const ENTER = 13;
    let current = document.querySelector("ul li.selected");

    if (e.which === DOWN && !current) {
      document.querySelector("ul li:first-child").classList.add("selected");
      document.querySelector(".ais-SearchBox-input").blur();
    } else if (e.which === DOWN && current) {
      let next = current.nextElementSibling;
      document.querySelector(".ais-SearchBox-input").blur();
      if (next) {
        current.classList.remove("selected");
        next.classList.add("selected");
      }
    } else if (e.which === UP) {
      let prev = current.previousElementSibling;
      document.querySelector(".ais-SearchBox-input").blur();
      if (prev) {
        current.classList.remove("selected");
        prev.classList.add("selected");
      }
    } else if (e.which === ENTER && current) {
      debugger;
      current.children[0].click();
    }
  }
}

export default SearchMenu;
