import { pushToDataLayer } from "/app/assets/javascripts/ga4/ga4_event.js"

const productCardContainers = document.querySelectorAll('.product-card-container');

productCardContainers.forEach(function(element) {
  element.addEventListener('click', function(event) {
    if(event.target.nodeName !== 'A') {
      window.open(element.attributes['data-click-url'].value, '_blank');

      pushToDataLayer({
        'event': 'click_affiliate',
        'affiliate_merchant': element.attributes['data-ga4-affiliate_merchant'].value,
        'affiliate_network': element.attributes['data-ga4-affiliate_network'].value,
        'affiliate_button_type': element.attributes['data-ga4-affiliate_button_type'].value,
        'affiliate_button_location': element.attributes['data-ga4-affiliate_button_location'].value,
        'price_upon_click': element.attributes['data-ga4-price_upon_click'].value,
        'product_name': element.attributes['data-ga4-product_name'].value
      });
      }
    });
});
