import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';

import { searchBox } from 'instantsearch.js/es/widgets';

export default class AlgoliaSearch {
  constructor(containerId, initialState) {
    const indexName = window.js_vars.algolia_article_index;
    this.containerId = containerId
    this.query = initialState ? initialState.query : "";

    this.instantSearch = instantsearch({
      indexName,
      searchClient: algoliasearch('M5OIGTW601', 'a98afffe5a350b389f931e5ed811c546'),
      initialUiState: {
        [indexName]: initialState,
      },
    })

    this.defaultWidgets = [
      searchBox({
        container: `#${containerId}`,
        placeholder: "Search...",
        showLoadingIndicator: false,
        showSubmit: true,
        showReset: true,
        cssClasses: {
          form: "search-form",
          input: `form-control ${containerId}-input`
        },
        templates: {
          submit: 'GO',
        },
        queryHook: (query, search) => {
          this.query = query;
          search(query);
        }
      }),
    ]
  }

  addWidgets(widgets) {
    this.instantSearch.addWidgets(this.defaultWidgets.concat(widgets));
  }

  start() {
    this.instantSearch.start();

    $(`#${this.containerId} button[type="submit"]`).click(e => {
      this.submitSearch();
    });
  }

  submitSearch() {
    window.location.href = `/search/articles?keywords=${this.query}&page=1`
  }
}
